<template>
  <div v-loading="loading">
    <SharedRelated
      v-if="!loading"
      :data="data"
      :detail="{ ...detail, detailType }"
      :tabs="allTabs"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceById } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

import SharedRelated from '@/components/shared/related'

export default {
  name: 'SharedPageRelated',
  components: {
    SharedRelated
  },
  data() {
    return {
      loading: true,
      data: this.$route.meta.data,
      detail: { name: '' }
    }
  },
  computed: {
    ...mapGetters(['language', 'accountOrg']),
    allTabs() {
      let tabs = getPlaceTypes(false).filter((t) => this.data.related.includes(t.value))
      if (this.accountOrg) {
        const menus = this.accountOrg.menus.split(',')
        tabs = tabs.filter((t) => menus.includes(t.value))
      }
      return tabs
    },
    detailType() {
      return getPlaceTypes(false).find((r) => r.value === this.data.type)
    },
    params() {
      return {
        id: this.$route.params.id,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'getDetailInfo'
  },
  beforeMount() {
    this.getDetailInfo()
  },
  methods: {
    getDetailInfo() {
      let request = null
      request = getPlaceById(this.params)
      this.loading = true
      request.then((response) => {
        this.detail = response
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
